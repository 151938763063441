<template>
    <b-container fluid>
        <iq-card class="mt-2" v-if="isOrgAdmin">
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('pump_install.watertesting_dashboard') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col lg="12" class="d-flex justify-content-center mb-1">
                                <h4 class="font-weight-bolder" style="color: #08a20c;">{{ currentOrgName }}</h4>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <iq-card class="mt-2" v-else-if="upazilaId">
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('pump_install.watertesting_dashboard') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col lg="4" class="text-center mb-1">
                                <div>
                                    <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('globalTrans.district') }} : {{ getDistrictName }}</h4>
                                </div>
                            </b-col>
                            <b-col lg="4" class="text-center mb-1">
                                <div>
                                    <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('globalTrans.upazila') }} : {{ upazilaName }}</h4>
                                </div>
                            </b-col>
                            <b-col lg="4" class="text-center mb-1">
                                <div>
                                    <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('pump_install.office') }} : {{ getOfficeName }}</h4>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <iq-card v-else>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('pump_install.watertesting_dashboard') }}</h4>
            </template>
            <template v-slot:body>
                <b-row class="mb-4">
                    <b-col>
                        <b-row>
                            <b-col lg="12" class="d-flex justify-content-center mb-4">
                                <h4 class="font-weight-bolder">{{ $t('irriWaterTest.water_testing_dashboard') }}</h4>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="2"></b-col>
                            <b-col lg="7" md="7" sm="12" xs="12"  class="justify-content-center">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('globalTrans.selectOrg')"
                                    label-for="org_id"
                                >
                                    <b-form-select
                                        plain
                                        v-model="search.org_id"
                                        :options="orgList"
                                        id="org_id"
                                        @input="filterData"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-overlay :show="loadingState">
            <b-row>
                <b-col md="4" sm="6" class="custom-box-shadow">
                    <div class="bg-success p-4 rounded mb-4 text-center">
                        <h3 class="text-white">{{ $n(totalApps) }}</h3>
                        <h5 class="text-white d-block">{{ $t('irriWaterTest.total_test_request')}}</h5>
                    </div>
                </b-col>
                <b-col md="4" sm="6" class="custom-box-shadow">
                    <div class="bg-success p-4 rounded mb-4 text-center">
                        <h3 class="text-white">{{ $n(totalPendingApps) }}</h3>
                        <h5 class="text-white d-block">{{ $t('irriWaterTest.pending_for_request')}}</h5>
                    </div>
                </b-col>
                <b-col md="4" sm="6" class="custom-box-shadow">
                    <div class="bg-success p-4 rounded mb-4 text-center">
                        <h3 class="text-white">{{ $n(totalPublishedApps) }}</h3>
                        <h5 class="text-white d-block">{{ $t('irriWaterTest.total_published_report')}}</h5>
                    </div>
                </b-col>
                <b-col md="4" sm="6" class="custom-box-shadow">
                    <router-link to="/irrigation/water-testing/drinking-water">
                        <div class="bg-success p-4 rounded mb-4 text-center">
                            <h3 class="text-white">{{ $n(totalDrinkingWater) }}</h3>
                            <h5 class="text-white d-block">{{ $t('irriWaterTest.total_drinking_water')}}</h5>
                        </div>
                    </router-link>
                </b-col>
                <b-col md="4" sm="6" class="custom-box-shadow">
                    <router-link to="/irrigation/water-testing/irrigation-water">
                        <div class="bg-success p-4 rounded mb-4 text-center">
                            <h3 class="text-white">{{ $n(totalIrrigationWater) }}</h3>
                            <h5 class="text-white d-block">{{ $t('irriWaterTest.total_irrigation_water')}}</h5>
                        </div>
                    </router-link>
                </b-col>
                <b-col md="4" sm="6" class="custom-box-shadow">
                    <router-link to="/irrigation/water-testing/industrial-water">
                        <div class="bg-success p-4 rounded mb-4 text-center">
                            <h3 class="text-white">{{ $n(totalIndustrialWater) }}</h3>
                            <h5 class="text-white d-block">{{ $t('irriWaterTest.total_industrial_water')}}</h5>
                        </div>
                    </router-link>
                </b-col>
            </b-row>
        </b-overlay>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { waterTestingDashboard } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
    name: 'IrrigationDashboard',
    data () {
        return {
            search: {
                org_id: 0,
                far_upazilla_id: 0
            },
            orgName: '',
            totalApps: 0,
            totalPendingApps: 0,
            totalPublishedApps: 0,
            totalDrinkingWater: 0,
            totalIrrigationWater: 0,
            totalIndustrialWater: 0,
            isOrgAdmin: false,
            upazilaId: 0,
            officeName: '',
            districtId: 0,
            currentOrg: {},
            dashboardData: {},
            loadingState: false
        }
    },
    computed: {
         ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        currentOrgName () {
            return (this.$i18n.locale === 'bn') ? this.currentOrg.text_bn : this.currentOrg.text
        },
        upazilaName () {
            let returnVal = ''
            const upazilas = this.$store.state.commonObj.upazilaList
            const upazila = upazilas.find(el => el.value === this.upazilaId)
            if (upazila) {
                returnVal = (this.$i18n.locale === 'bn') ? upazila.text_bn : upazila.text
            }
            return returnVal
        },
        getDistrictName () {
            const district = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(this.districtId))
            if (typeof district === 'undefined') {
                return ''
            } else {
                return district.text
            }
        },
        getOfficeName () {
            return (this.officeName) ? this.officeName : ''
        },
        orgList () {
            return this.$store.state.commonObj.organizationProfileList
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    created () {
        this.checkUser()
    },
    methods: {
        checkUser () {
            const user = this.$store.state.Auth.authUser
            this.isOrgAdmin = user.is_org_admin
            if (user.role_id === 0 && user.is_org_admin === 1) {
                this.search.org_id = user.org_id
                this.filterData()
                const org = this.$store.state.orgList.find(el => el.value === user.org_id)
                if (org) {
                    this.currentOrg = org
                }
            } else if (user.role_id === 0) {
                this.search.org_id = user.office_detail.org_id
                this.search.far_upazilla_id = user.office_detail.upazilla_id
                this.upazilaId = user.office_detail.upazilla_id
                this.districtId = user.office_detail.district_id
                this.officeName = (this.$i18n.locale === 'bn') ? user.office_detail.office_name_bn : user.office_detail.office_name
                this.filterData()
            } else {
                this.filterData()
            }
        },
        filterData () {
            this.loadingState = true
            RestApi.getData(irriSchemeServiceBaseUrl, waterTestingDashboard, this.search)
            .then(response => {
                if (response.success) {
                    this.totalApps = response.data.total_apps
                    this.totalPendingApps = response.data.total_pending
                    this.totalPublishedApps = response.data.total_published
                    this.totalDrinkingWater = response.data.total_drinking_water
                    this.totalIrrigationWater = response.data.total_irrigation_water
                    this.totalIndustrialWater = response.data.total_industrial_water
                } else {
                    this.totalApps = 0
                    this.totalPendingApps = 0
                    this.totalPublishedApps = 0
                    this.totalDrinkingWater = 0
                    this.totalIrrigationWater = 0
                    this.totalIndustrialWater = 0
                }
                this.loadingState = false
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .custom-box-shadow div {
        transition: box-shadow 300ms;
        cursor: pointer;
    }
    .custom-box-shadow:hover div {
        -webkit-box-shadow: 0px 5px 15px -1px rgba(61,61,61,1);
        -moz-box-shadow: 0px 5px 15px -1px rgba(61,61,61,1);
        box-shadow: 0px 5px 15px -1px rgba(61,61,61,1);
        transition-timing-function: ease-in-out;
    }
    .chart-1 {
        width: 380px;
        height: 100%;
    }
</style>
